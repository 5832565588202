<template>
  <div class="entrustChild">
    <div class="banner">
      <img
        class="bannerImg"
        :src="bannerList.topicPicture"
        alt="图片加载中..."
      />
      <div class="myAppoint" @click="myAppoint">
        <div class="txt">我的预约</div>
        <div class="img"><img src="./img/arrow.png" alt="" /></div>
      </div>
    </div>
    <div class="title">
      <div
        :class="['title-l', { te: type == 1 }]"
        @click="confirm(typeList[0])"
      >
        课程预约
      </div>
      <div
        :class="['title-r', { te: type == 2 }]"
        @click="confirm(typeList[1])"
      >
        时段预约
      </div>
    </div>
    <!-- 开放日期 -->
    <div class="time" ref="time">
      <div
        v-for="(item, index) in timeList"
        :key="index"
        :class="['item', { active: index == oIndex }]"
        @click="selectTime(item, index)"
      >
        <div class="item-t">{{ item.key }}</div>
        <div class="item-b">{{ item.value }}</div>
      </div>
    </div>
    <div class="main">
      <!-- 课程预约start -->
      <div class="main-t" v-if="type == 1">
        <div class="item" v-if="amList.length != 0">
          <div class="item-l">
            <div>
              <p>上</p>
              <p>午</p>
            </div>
          </div>
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == '上午'"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>
                    {{ `${item.activityBeginTime}~${item.activityEndTime}` }}
                  </h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-c">
              <div class="btn">
                <div class="btn-r">
                  <div
                    :class="[
                      'opera1',
                      {
                        full:
                          amList[0].applicantsNum == amList[0].applicantsMax,
                      },
                    ]"
                    @click="toAppoint(amList[0].scheduleId)"
                    v-if="amList[amList.length - 1].orderStatus == '预约'"
                  >
                    预约
                  </div>
                  <div
                    class="opera1 full"
                    v-if="amList[amList.length - 1].orderStatus == '不可预约'"
                  >
                    已过期
                  </div>
                  <div
                    class="opera1 full"
                    v-if="amList[amList.length - 1].orderStatus == '已约满'"
                  >
                    已约满
                  </div>
                  <div
                    class="opera2"
                    v-if="amList[amList.length - 1].orderStatus == '待审核'"
                  >
                    待审核
                  </div>
                  <div
                    class="opera2"
                    v-if="amList[amList.length - 1].orderStatus == '审核失败'"
                  >
                    审核失败
                  </div>
                  <div
                    class="opera3"
                    v-if="amList[amList.length - 1].orderStatus == '查看视频'"
                    @click="toLook(amList[0].scheduleId)"
                  >
                    查看视频
                  </div>
                  <div
                    class="opera3"
                    v-if="amList[amList.length - 1].orderStatus == '待开始'"
                  >
                    待开始
                  </div>
                  <div
                    v-if="amList[amList.length - 1].orderStatus == '评价'"
                    class="opera1"
                    @click="evaluate(amList[0].scheduleId)"
                  >
                    评价
                  </div>
                  <div
                    class="opera3"
                    v-if="amList[amList.length - 1].orderStatus == '已评价'"
                  >
                    已评价
                  </div>
                </div>
              </div>
              <div class="txt">
                <div class="txt-c">
                  已预约{{ amList[0].applicantsNum }}/{{
                    amList[0].applicantsMax
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="pmList.length != 0">
          <div class="item-l">
            <div>
              <p>下</p>
              <p>午</p>
            </div>
          </div>
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == '下午'"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>
                    {{ `${item.activityBeginTime}~${item.activityEndTime}` }}
                  </h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-c">
              <div class="btn">
                <!-- <div class="btn-l">预约结束</div> -->
                <div class="btn-r">
                  <div
                    :class="[
                      'opera1',
                      {
                        full:
                          pmList[0].applicantsNum == pmList[0].applicantsMax,
                      },
                    ]"
                    @click="toAppoint(pmList[0].scheduleId)"
                    v-if="pmList[pmList.length - 1].orderStatus == '预约'"
                  >
                    预约
                  </div>
                  <div
                    class="opera1 full"
                    v-if="pmList[pmList.length - 1].orderStatus == '不可预约'"
                  >
                    已过期
                  </div>
                  <div
                    class="opera1 full"
                    v-if="pmList[pmList.length - 1].orderStatus == '已约满'"
                  >
                    已约满
                  </div>

                  <div
                    class="opera2"
                    v-if="pmList[pmList.length - 1].orderStatus == '待审核'"
                  >
                    待审核
                  </div>
                  <div
                    class="opera2"
                    v-if="pmList[pmList.length - 1].orderStatus == '审核失败'"
                  >
                    审核失败
                  </div>
                  <div
                    class="opera3"
                    v-if="pmList[pmList.length - 1].orderStatus == '查看视频'"
                    @click="toLook(pmList[0].scheduleId)"
                  >
                    查看视频
                  </div>
                  <div
                    class="opera3"
                    v-if="pmList[pmList.length - 1].orderStatus == '待开始'"
                  >
                    待开始
                  </div>

                  <div
                    class="opera1"
                    v-if="pmList[pmList.length - 1].orderStatus == '评价'"
                    @click="evaluate(pmList[pmList.length - 1].scheduleId)"
                  >
                    评价
                  </div>
                  <div
                    class="opera3"
                    v-if="pmList[pmList.length - 1].orderStatus == '已评价'"
                  >
                    已评价
                  </div>
                </div>
              </div>
              <div class="txt">
                <div class="txt-c">
                  已预约{{ pmList[0].applicantsNum }}/{{
                    pmList[0].applicantsMax
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 课程预约end -->
      <!-- 托幼预约start  -->
      <div class="Kindergartens" v-else>
        <div
          class="item"
          v-if="kindData && kindData.voList && kindData.voList.length != 0"
        >
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in kindData.voList"
              :key="index"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <h3>
                    {{ `${item.beginTime}~${item.endTime}` }}
                  </h3>
                </div>
              </div>

              <div class="list-r">
                <div class="item-r-c">
                  <div class="btn">
                    <div class="btn-r">
                      <div
                        :class="[
                          'opera1',
                          {
                            full: item.applicantsNum == item.applicantsMax,
                          },
                          {
                            selectAppoint: item.orderStatus == '预约',
                          },
                        ]"
                        @click="toSaveAppoint(kindData, item)"
                        v-if="item.orderStatus == '预约'"
                      >
                        <img
                          v-if="item.isSelected"
                          src="./img/appointed.png"
                          alt=""
                        />
                        <img v-else src="./img/appoint.png" alt="" />
                      </div>
                      <div
                        class="opera1 full"
                        v-if="item.orderStatus == '不可预约'"
                      >
                        已过期
                      </div>
                      <div
                        class="opera1 full"
                        v-if="item.orderStatus == '已约满'"
                      >
                        已约满
                      </div>
                      <div class="opera2" v-if="item.orderStatus == '待审核'">
                        待审核
                      </div>
                      <div class="opera2" v-if="item.orderStatus == '审核失败'">
                        审核失败
                      </div>
                      <div
                        class="opera3"
                        v-if="item.orderStatus == '查看视频'"
                        @click="toLook(kindData.scheduleId, item.detailId)"
                      >
                        查看视频
                      </div>
                      <div class="opera3" v-if="item.orderStatus == '待开始'">
                        待开始
                      </div>
                      <div
                        class="opera1"
                        v-if="item.orderStatus == '评价'"
                        @click="evaluate(kindData.scheduleId, item.detailId)"
                      >
                        评价
                      </div>
                      <div class="opera3" v-if="item.orderStatus == '已评价'">
                        已评价
                      </div>
                    </div>
                  </div>
                  <div class="txt">
                    <div class="txt-c">
                      已预约{{ item.applicantsNum }}/{{ item.applicantsMax }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 托幼预约start  -->
      <v-empty
        description="当日无课程安排"
        v-if="type == 1 && amList.length == 0 && pmList.length == 0"
      ></v-empty>
      <v-empty
        description="当日无课程安排"
        v-if="type == 2 && Object.keys(kindData).length == 0"
      ></v-empty>
      <div class="subscirbe" v-if="type == 2" @click="subscirbe">提交申请</div>
    </div>
    <div class="evaluateDialog" v-if="isShowEvaluate">
      <div class="dialogMain">
        <div class="mainBody">
          <div class="mainTitle">请为本次托幼活动打分（必填）</div>
          <div class="mainScore">
            <div class="rate" v-for="i in 5" :key="i">
              <div class="rateIcon">
                <img
                  @click="form.grade = i"
                  :src="
                    require(form.grade >= i
                      ? './img/starSelect.png'
                      : './img/star.png')
                  "
                  alt=""
                />
              </div>
              <div class="rateTxt">{{ scoreMap[i] }}</div>
            </div>
          </div>
          <div class="mainUpload">
            <v-upload
              class="oItem-b_upload"
              :imgUrl.sync="form.feedbackPicture"
              :activityPicture.sync="form.feedbackPicture"
              :maxCount="6"
              ref="load"
            ></v-upload>
            <div class="txt">最多可上传6张照片</div>
          </div>
          <div class="mainInput">
            <v-input
              v-model="form.feedbacksSuggestion"
              placeholder="评价理由"
              type="textarea"
              :maxlength="30"
              :showWordLimit="true"
            ></v-input>
          </div>
          <div class="submitBtn">
            <div class="btn" @click="evaluateSubmit">提交</div>
          </div>
        </div>
        <div class="mainClose" @click="evaluateClose">
          <img src="./img/close.png" alt="" />
        </div>
      </div>
    </div>
    <v-picker
      :columns="typeList"
      :defaultIndex="defaultIndex"
      :valueShow="isShowType"
      valueKey="label"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import { weekArrMap, weekListMap } from "./map.js";
import {
  getClassDataByDayUrl,
  getComingDateOfClassUrl,
  getBannerListUrl,
  appraiseUrl,
} from "./api.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "entrustChild",
  data() {
    return {
      oIndex: 14,
      oOriginIndex: 14,
      postData: [],
      kindData: {}, //托幼数据
      amList: [],
      pmList: [],
      timeList: [],
      scheduleId: "",
      isShowEvaluate: false,
      indexTime: moment(new Date()).format("YYYY-MM-DD"),
      /* dialog */
      form: {
        grade: undefined,
        feedbackPicture: "",
        feedbacksSuggestion: "",
      },
      isInfo: false,
      scoreMap: {
        1: "非常差",
        2: "差",
        3: "一般",
        4: "满意",
        5: "非常满意",
      },
      isShowType: false,
      typeList: [
        {
          type: 1,
          label: "按课程预约",
        },
        {
          type: 2,
          label: "按时段预约",
        },
      ],
      type: 1,
      detailId: "", //场次
      defaultIndex: 0, // picker默认选择展示
      appointData: [],
      bannerList: {},
      recentDate: "",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async created() {
    this.getBannerList();
    if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
      if (localStorage.getItem("appointmentType")) {
        const result = JSON.parse(localStorage.getItem("appointmentType"));
        const { type, oIndex } = result;
        type == 1 ? (this.defaultIndex = 0) : (this.defaultIndex = 1);
        this.type = type;
        this.oIndex = oIndex;
      }
      this.getTimeList();
      await this.getComingDateOfClass();
      if (this.recentDate) {
        if (this.oOriginIndex == this.oIndex) {
          let dateTime = new Date(this.recentDate).getTime();
          let lastTime = new Date(
            this.timeList[this.timeList.length - 1].goal
          ).getTime();
          if (lastTime - dateTime >= 0) {
            for (let i = 0; i < this.timeList.length - 1; i++) {
              if (dateTime == new Date(this.timeList[i].goal).getTime()) {
                this.oIndex = i;
                break;
              }
            }
          }
        }
      }
      if (this.type == 1) {
        this.getPartyList(this.timeList[this.oIndex].goal);
      } else {
        await this.getKindList(
          this.timeList[this.oIndex].goal,
          weekListMap[this.timeList[this.oIndex].key]
        );
        this.renderSelect();
        this.$forceUpdate();
      }
    }
  },
  mounted() {
    gloabalCount("", 57, 1);
    if (this.$refs.time && document.querySelector(".active")) {
      this.$refs.time.scrollTo(
        document.querySelector(".active").offsetLeft * 0.835,
        0
      );
    }
  },
  methods: {
    async getComingDateOfClass() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        type: this.type,
      };
      let res = await this.$axios.get(`${getComingDateOfClassUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.data) {
          this.recentDate = res.data;
        }
      } else {
      }
    },
    async getBannerList() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
        topicType: 50, // 1. 和伙人风采 2.超话 3. 活动 6.创业达人
        curPage: 1,
        pageSize: 10,
      };
      let res = await this.$axios.get(`${getBannerListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.data.records) {
          this.bannerList = res.data.records[0];
        }
      } else {
      }
    },
    subscirbe() {
      if (localStorage.getItem("appointData")) {
        this.appointData = JSON.parse(localStorage.getItem("appointData"));
      }
      if (this.appointData.length == 0) {
        this.$toast({ message: "请先选择时段！", duration: 2000 });
        return;
      }
      this.storageAppointment();
      this.$router.push({
        name: "entrustChildList",
        query: {
          type: this.type,
          oIndex: this.oIndex,
        },
      });
    },
    toSaveAppoint(kindData, item) {
      item.isSelected = !item.isSelected;
      if (localStorage.getItem("appointData")) {
        this.appointData = JSON.parse(localStorage.getItem("appointData"));
      }
      let scheduleIdList = [];
      this.appointData.forEach((ele) => {
        if (ele.scheduleId) {
          scheduleIdList.push(ele.scheduleId);
        }
      });
      if (scheduleIdList.indexOf(kindData.scheduleId) != -1) {
        console.log("此课程有了");
        if (item.isSelected) {
          console.log("没有这个场次，加入");
          for (let i = 0; i <= this.appointData.length - 1; i++) {
            if (this.appointData[i].scheduleId == kindData.scheduleId) {
              this.appointData[i].detailId.push({
                beginTime: item.beginTime,
                endTime: item.endTime,
                weekName: item.weekName,
                detailId: item.detailId,
              });
              break;
            }
          }
        } else {
          console.log("有这个场次，删除");
          let detailIdList = [];
          let index;
          for (let i = 0; i <= this.appointData.length - 1; i++) {
            if (this.appointData[i].scheduleId == kindData.scheduleId) {
              for (
                let j = 0;
                j <= this.appointData[i].detailId.length - 1;
                j++
              ) {
                detailIdList.push(this.appointData[i].detailId[j].detailId);
              }
              index = detailIdList.indexOf(item.detailId);
              this.appointData[i].detailId.splice(index, 1);
              if (this.appointData[i].detailId.length == 0) {
                this.appointData.splice(i, 1);
              }
              break;
            }
          }
        }
      } else {
        let data = {
          scheduleId: kindData.scheduleId,
          dayName: kindData.dayName,
          detailId: [
            {
              beginTime: item.beginTime,
              endTime: item.endTime,
              weekName: item.weekName,
              detailId: item.detailId,
            },
          ],
        };
        this.appointData.push(data);
      }
      localStorage.setItem("appointData", JSON.stringify(this.appointData));
      this.$forceUpdate();
      console.log(this.kindData, this.appointData, "----");
    },
    closeInfo() {
      this.isInfo = false;
    },
    toinfo() {
      // this.$router.push({
      //   name: "entrustChildInfo",
      // });
      this.isInfo = true;
    },
    //选择方式确定
    async confirm(item) {
      item.type == 1 ? (this.defaultIndex = 0) : (this.defaultIndex = 1);
      this.type = item.type;
      await this.getComingDateOfClass();
      if (this.recentDate) {
        let dateTime = new Date(this.recentDate).getTime();
        let lastTime = new Date(
          this.timeList[this.timeList.length - 1].goal
        ).getTime();
        if (lastTime - dateTime >= 0) {
          for (let i = 0; i < this.timeList.length - 1; i++) {
            if (dateTime == new Date(this.timeList[i].goal).getTime()) {
              this.oIndex = i;
              break;
            }
          }
        }
      }
      this.storageAppointment();
      if (this.type == 1) {
        this.getPartyList(this.timeList[this.oIndex].goal);
      } else {
        await this.getKindList(
          this.timeList[this.oIndex].goal,
          weekListMap[this.timeList[this.oIndex].key]
        );
        this.renderSelect();
        this.$forceUpdate();
      }
    },
    cancelPick() {
      this.isShowType = false;
    },
    clickOverlay() {
      this.isShowType = false;
    },
    //选择预约方式
    handelSelectType() {
      this.isShowType = true;
    },
    // 我的预约
    myAppoint() {
      this.storageAppointment();
      this.$router.push({
        name: "myEntrustChild",
        query: {
          type: this.type,
        },
      });
    },
    // 选择日期
    async selectTime(item, index) {
      this.oIndex = index;
      this.postData = [];
      this.kindData = {};
      this.amList = [];
      this.pmList = [];
      if (this.type == 1) {
        this.getPartyList(item.goal);
      } else {
        await this.getKindList(item.goal, weekListMap[item.key]);
        this.renderSelect();
        this.$forceUpdate();
      }
    },
    renderSelect() {
      if (!localStorage.getItem("appointData")) {
        return;
      }
      let appointData = JSON.parse(localStorage.getItem("appointData"));
      let oList = [];
      appointData.forEach((ele) => {
        if (ele.scheduleId == this.kindData.scheduleId) {
          oList = ele.detailId;
        }
      });
      if (this.kindData.voList) {
        this.kindData.voList.forEach((ele) => {
          oList.forEach((item) => {
            if (ele.detailId == item.detailId) {
              ele.isSelected = true;
            }
          });
        });
      }
    },
    // 查看视频
    toLook(scheduleId, detailid) {
      this.storageAppointment();
      this.$router.push({
        name: "entrustChildVideo",
        query: {
          scheduleId: scheduleId,
          detailid: detailid,
        },
      });
    },
    // 评价
    evaluate(scheduleId, detailId) {
      this.storageAppointment();
      this.scheduleId = scheduleId;
      if (detailId) {
        this.detailId = detailId;
      }
      this.isShowEvaluate = true;
    },
    // 去预约
    toAppoint(scheduleId, detailId) {
      this.storageAppointment();
      this.$router.push({
        name: "entrustChildList",
        query: {
          scheduleId: scheduleId,
          type: this.type,
          oIndex: this.oIndex,
          detailId,
          dayName: this.timeList[this.oIndex].goal,
        },
      });
    },
    // 缓存预约方式以及当前日期下标位置
    storageAppointment() {
      let params = {
        type: this.type,
        oIndex: this.oIndex,
      };
      localStorage.setItem("appointmentType", JSON.stringify(params));
    },
    // 关闭评价弹框
    evaluateClose() {
      console.log(JSON.stringify(this.form), "--this.form");
      this.isShowEvaluate = false;
      this.form = {
        grade: undefined,
        feedbackPicture: "",
        feedbacksSuggestion: "",
      };
    },
    /* 辅助方法 */
    // 返回固定格式的时间
    returnTime(data) {
      if (data) {
        return moment(data).format("YYYY-MM-DD");
      }
    },
    // 获取前后15天内的日期信息
    getTimeList() {
      const timeList = [];
      let num = 15;
      // 往前15天
      for (let i = 1; i < num; i++) {
        let obj = {
          key: weekArrMap[
            moment(new Date())
              .subtract(num - i, "days")
              .weekday()
          ],
          value: moment(new Date())
            .subtract(num - i, "days")
            .format("MM.DD"),
          goal: moment(new Date())
            .subtract(num - i, "days")
            .format("YYYY-MM-DD"),
        };
        timeList.push(obj);
      }
      // 往后15天
      for (let i = 0; i < num; i++) {
        let obj = {
          key: weekArrMap[moment(new Date()).add(i, "days").weekday()],
          value: moment(new Date()).add(i, "days").format("MM.DD"),
          goal: moment(new Date()).add(i, "days").format("YYYY-MM-DD"),
        };
        timeList.push(obj);
      }
      this.timeList = timeList;
      console.log(this.timeList);
    },
    /* 接口 */
    // 查询对应日期的课程
    async getPartyList(dayData) {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        userId: this.userId,
        dayData,
        type: 1,
      };
      this.amList = [];
      this.pmList = [];
      let res = await this.$axios.get(getClassDataByDayUrl, { params });
      if (res.code === 200) {
        this.postData = res.data;
        this.postData.forEach((ele) => {
          if (ele.timeDuring == "上午") {
            this.amList.push(ele);
          }
          if (ele.timeDuring == "下午") {
            this.pmList.push(ele);
          }
        });
      }
    },
    // 查询托幼预约接口
    async getKindList(dayData, weekName) {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        userId: this.userId,
        type: 2,
        dayData,
        weekName,
      };
      let res = await this.$axios.get(getClassDataByDayUrl, { params });
      if (res.code === 200) {
        if (res.data?.voList) {
          this.kindData = res.data;
          if (this.kindData.voList) {
            this.kindData.voList.forEach((ele) => {
              ele.isSelected = false;
            });
          }
        }
      }
    },
    // 提交评价内容
    async evaluateSubmit() {
      if (!this.form.grade) {
        this.$toast({ message: "请先打分！", duration: 2000 });
        return;
      }
      let params;
      if (this.type === 1) {
        params = {
          userId: this.userId,
          scheduleId: this.scheduleId,
          orderStatus: "已评价",
          ...this.form,
        };
      } else {
        params = {
          userId: this.userId,
          scheduleId: this.scheduleId,
          orderStatus: "已评价",
          detailId: this.detailId,
          ...this.form,
        };
      }
      let res = await this.$axios.post(appraiseUrl, params);

      if (res.code === 200) {
        // 关闭评价弹框
        this.evaluateClose();
        if (this.type == 1) {
          this.getPartyList(this.timeList[this.oIndex].goal);
        } else {
          this.getKindList(
            this.timeList[this.oIndex].goal,
            weekListMap[this.timeList[this.oIndex].key]
          );
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChild {
  height: 100vh;
  .dialog-info {
    width: 90%;
    height: 70%;
    background: #fff;
    border-radius: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    .box {
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 30px;
    }
    .close {
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: -90px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 9999;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .entrustChildInfo {
      h3 {
        font-size: 36px;
        text-align: center;
        margin: 20px 0;
        font-weight: 700;
      }
      p {
        font-size: 30px;
        text-indent: 2em;
        margin: 20px 0;
      }
    }
  }
  .banner {
    height: 319px;
    position: relative;
    .myAppoint {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 30px;
      left: 38px;
      .txt {
        font-size: 30px;
        font-weight: 400;
        color: rgba(0, 125, 242, 1);
        line-height: 40px;
        margin-right: 10px;
      }
      .img {
        width: 21px;
        height: 32px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bannerImg {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    padding: 32px;
    display: flex;
    justify-content: space-around;
    font-size: 30px;
    color: #333;
    .title-l {
    }
    .te {
      font-weight: 700;
      font-size: 32px;
      color: #000;
    }
  }
  .time {
    margin: 0 34px 50px;
    display: flex;
    // justify-content: center;
    text-align: center;
    height: 134px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      box-sizing: border-box;
      position: relative;
      min-width: 104px;
      height: 100%;
      padding: 14px;
      border-radius: 8px;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
    .item-t {
      font-size: 26px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
    }
    .item-b {
      margin-top: 14px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 34px;
    }
    .active {
      background: #ff9614ff;
      .item-t,
      .item-b {
        color: #ffffffff;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 14px;
        left: 0;
        right: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background: #ffffff;
        border-radius: 50%;
      }
    }
  }
  .main {
    padding: 0 34px 30px;
    .subscirbe {
      width: 540px;
      height: 60px;
      text-align: center;
      font-size: 28px;
      background: #ff9614ff;
      color: #fff;
      line-height: 60px;
      border-radius: 40px;
      margin: 100px auto;
    }
    .item {
      display: flex;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      margin-bottom: 30px;
      .item-l {
        width: 84px;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        color: #151c39;
        line-height: 50px;
      }
      .item-c {
        flex: 1;
        .list {
          display: flex;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          }
          .list-l {
            flex: 1;
            flex-shrink: 0;
            padding: 14px 16px;
            box-sizing: border-box;
          }
          .list-r {
            flex: 1;
            flex-shrink: 0;
            padding: 14px 16px;
            box-sizing: border-box;
          }
          .list-l {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #151c39;
            line-height: 34px;
            border-right: 1px solid rgba(0, 0, 0, 0.06);
          }
          .list-r {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #151c39;
            line-height: 34px;
          }
        }
      }
      .item-r {
        width: 182px;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .btn {
          .btn-l {
            font-size: 28px;
            font-weight: 400;
            color: #a8a8a8;
            line-height: 40px;
          }
          .btn-r {
            .opera1 {
              width: 124px;
              background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
              border-radius: 10px;
              font-size: 24px;
              font-weight: 500;
              color: #ffffff;
              line-height: 52px;
              margin: 0 auto;
              &.full {
                background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
                color: #a7a7a7;
              }
            }
            .opera2 {
              width: 124px;
              border: 2px solid #ff9614;
              border-radius: 10px;
              font-size: 28px;
              font-weight: bold;
              color: #ff9614;
              line-height: 48px;
              margin: 0 auto;
            }
            .opera3 {
              font-size: 24px;
              font-weight: bold;
              color: #ff9614;
              line-height: 52px;
            }
          }
        }
        .txt {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 28px;
        }
      }
    }
    .Kindergartens {
      .item {
        display: flex;
        background: #ffffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 16px;
        margin-bottom: 30px;
        .item-l {
          width: 84px;
          border-right: 1px solid rgba(0, 0, 0, 0.06);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          font-weight: 600;
          color: #151c39;
          line-height: 50px;
        }
        .item-c {
          flex: 1;
          .list {
            display: flex;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            }
            .list-l {
              flex: 1;
              flex-shrink: 0;
              padding: 14px 16px;
              box-sizing: border-box;
            }
            .list-c {
              flex: 1;
              flex-shrink: 0;
              padding: 14px 16px;
              box-sizing: border-box;
            }
            .list-l {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              font-weight: 400;
              color: #151c39;
              line-height: 34px;
              border-right: 1px solid rgba(0, 0, 0, 0.06);
            }
            .list-c {
              display: flex;
              text-align: center;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              font-weight: 400;
              color: #151c39;
              line-height: 34px;
            }
            .list-r {
              flex: 1;
              border-left: 1px solid rgba(0, 0, 0, 0.06);
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              .btn {
                .btn-l {
                  font-size: 28px;
                  font-weight: 400;
                  color: #a8a8a8;
                  line-height: 40px;
                }
                .btn-r {
                  .opera1 {
                    width: 124px;
                    background: linear-gradient(
                      180deg,
                      #ffc055 0%,
                      #fe9514 100%
                    );
                    border-radius: 10px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 52px;
                    margin: 0 auto;
                    &.full {
                      background: linear-gradient(
                        180deg,
                        #ededed 0%,
                        #d8d8d8 100%
                      );
                      color: #a7a7a7;
                    }
                  }
                  .selectAppoint {
                    width: 46px;
                    height: 46px;
                    background: none;
                    border-radius: none;
                    img {
                      width: 100%;
                    }
                  }
                  .opera2 {
                    width: 124px;
                    border: 2px solid #ff9614;
                    border-radius: 10px;
                    font-size: 28px;
                    font-weight: bold;
                    color: #ff9614;
                    line-height: 48px;
                    margin: 0 auto;
                  }
                  .opera3 {
                    font-size: 24px;
                    font-weight: bold;
                    color: #ff9614;
                    line-height: 52px;
                  }
                }
              }
              .txt {
                margin-top: 8px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}
.evaluateDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  .dialogMain {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
    box-sizing: border-box;
    width: 656px;
    .mainBody {
      padding: 74px 34px 46px;
      border-radius: 16px;
      background: #fff;
      overflow: auto;
      max-height: calc(100vh - 286px);
      .mainTitle {
        font-size: 32px;
        // font-weight: bold;
        color: #333333;
        line-height: 44px;
      }
      .mainScore {
        display: flex;
        padding: 18px 14px 24px;
        white-space: nowrap;
        .rate {
          margin-right: 48px;
          text-align: center;
          .rateIcon {
            // width: 48px;
            height: 48px;
            margin-bottom: 12px;
            img {
              width: 48px;
              height: 48px;
              overflow: hidden;
              vertical-align: middle;
            }
          }
          .rateTxt {
            display: flex;
            justify-content: center;
            width: 48px;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
          }
        }
      }
      .mainUpload {
        ::v-deep .van-uploader__upload {
          width: 140px;
          height: 140px;
        }
        ::v-deep .van-uploader__preview {
          border: 0;
          .van-uploader__preview-image {
            width: 140px;
            height: 140px;
          }
          .van-uploader__preview-delete {
            width: 28px;
            height: 28px;
            border-radius: 0 0 0 24px;
            .van-uploader__preview-delete-icon {
              position: absolute;
              top: -7px;
              right: -7px;
              display: block;
            }
          }
        }
        .txt {
          margin-top: 14px;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
      .mainInput {
        margin-top: 26px;
        ::v-deep .van-field {
          background: rgba(0, 0, 0, 0.05);
          border-radius: 16px;
          .van-field__word-limit {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
      .submitBtn {
        margin-top: 90px;
        width: 100%;
        display: flex;
        justify-content: center;
        .btn {
          width: 550px;
          height: 66px;
          font-size: 28px;
          line-height: 66px;
          text-align: center;
          font-weight: bold;
          border-radius: 10px;
          color: #ffffff;
          background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
          &.light {
          }
        }
      }
    }
    .mainClose {
      width: 76px;
      height: 76px;
      margin: 62px auto 0;
      > img {
        width: 76px;
        height: 76px;
      }
    }
  }
}
</style>
